@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.background_image_container {
  position: relative;
  min-height: calc(100vh - 80px);
  padding: 20px;
}

.background_image_inner {
  position: relative;
  //min-height: 100%;
  min-height: calc(100vh - 120px);
  background-color: $white;
}

@media (max-height : 650px) {
  .background_image_container {
    //height: auto;
  }
  .background_image_inner {
    //height: auto;
  }
}
