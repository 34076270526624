@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables";

.DeliveryHome__itemList {
  padding: 10px 0 0;
  width: 100%;
  flex-grow: 1;

  margin-bottom: 20px;
}

.DeliveryHome__items {
  width: 100%;
}

.DeliveryHome__buttons {
  width: 100%;
  margin-top: 20px;
}
.DeliveryHome__otherAddress {
  margin-bottom: 20px;
}

