@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.floatLabel {
  position: relative;
  width: 100%;

  :global(.ant-input) {
    font-size: 16px;
    padding: 0 0 8px;
    border: none;
    border-bottom: 1px solid $dark-grey;
    background-color: transparent !important;
    border-radius: 0;
  }
  :global(.ant-input:hover) {
    border: none;
    box-shadow: none !important;
    border-bottom: 1px solid $dark-grey;
  }
  :global(.ant-form-item-explain) {
    font-size: 10px;
  }

  :global(.ant-select) {
    padding: 0;
    border: none;
    border-bottom: 1px solid $dark-grey;
    background-color: transparent !important;
    border-radius: 0;
  }
  :global(.ant-select-selector) {
    border: none !important;
  }
  :global(.ant-select-selection-search) {
    left: 0 !important;
    right: 0 !important;
  }
  :global(.ant-select:hover) {
    border: none;
    box-shadow: none !important;
    border-bottom: 1px solid $dark-grey;
  }

  :global(div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child)) {
    display: none;
  }
}

.floatLabel__label {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 8px;
  transition: 0.2s ease all;

  color: $dark-grey;
  font-size: 14px !important;
}

.floatLabel__labelFloating {
  top: -16px;
  font-size: 12px !important;
  padding: 0 4px;
  margin-left: -4px;
  background-color: transparent;
}

.floatLabel__help {
  position: absolute;
  bottom: -24px;
  left: 0;
}
