@import "../../../globals/styles/_variables.scss";.home__container {
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
}

.home {
  height: calc(80vh - 120px);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.home__image {
  height: 140px;
}

.home__text {
  margin-bottom: 20px;
}

.home__startButton {
  width: 90%; padding: 16px;
  font-size: 16px; line-height: 20px; text-align: center; color: black; font-weight: 300;
  background: rgba(0, 0, 0, 0.2); border-radius: 6px;
}
