@import "../../globals/styles/_variables.scss";.privacyPolicy {
  position: relative;
  width: 100%;
  text-align: center;
}

.privacyPolicy__text {
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.454545px;
  text-align: left;

  h2 {
    text-align: left;
    margin-bottom: 6px;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.454545px;
  }

  list-style: inside;
}

.privacyPolicy__backButton {
  position: absolute;
  top: 20px;
  left: 20px;
}
