@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.switchSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.454545px;

  p {
    text-align: center;
    line-height: 12px;
  }
}

.switchSelect__switchContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchSelect__background {
  position: absolute;
  width: 46px;
  height: 22px;
  background-color: $dark-grey;
  border-radius: 100px;
}

.switchSelect__text {
  flex: 1 1 0;
}

.switchSelect__tooltip {
  flex: 1 1 0;
  display: flex;
  align-items: center;

  :global(.anticon) {
    margin-left: 4px;
  }
}