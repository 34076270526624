@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.productStock {
  margin-top: 15px;
  border: 1px solid $dark-grey;
  width: 100%;
}

.productStock__header {
  background-color: $dark-grey;
  padding: 5px 8px;

  h2 {
    text-align: center;
    color: $white;
  }
}

.productStock__listItem {
  margin: 4px 8px 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productStock__newStock {
  font-weight: 600;
  margin-bottom: 4px;
}

