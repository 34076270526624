@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.container {
  position: relative;
}

.button {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 15px;
  border: none;
  text-decoration: none;
  font-size: 10px;
  line-height: 12.19px;
  letter-spacing: 0.15px;
  text-align: center;

  p {
    position: relative;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.2;
  }
}

.button__plain {
  color: $white;
  background-color: $dark-brown;
  border: 1px solid $dark-brown;
  transition: color 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: $white;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);
  }
  &:focus {
    color: $dark-brown;

    &::before {
      transform: scaleX(1);
    }
  }
}

.button__outlined {
  color: $dark-brown;
  border: 1px solid $dark-brown;
  background-color: $white;
  transition: color 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-color: $dark-brown;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);
  }
  &:active {
    color: white;

    &::before {
      transform: scaleX(1);
    }
  }
}

.button__text {
  color: $black;
  background-color: transparent;
  text-decoration: underline;
  font-size: 12px;
  line-height: 26px;
  padding: 0;
}

.button__small {
  width: 150px;
}

.button__large {
  width: 100%;
}

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 8px));
}
