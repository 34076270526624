@import "../../globals/styles/_variables.scss";.container {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;

    .item {
        flex: 1;
        border: 1px solid $black;
        margin-left: 8px;
        opacity: 0.3;
        
        &:first-child {
            margin-left: 0;
        }

        &--active {
            opacity: 1;
        }
    }
}