@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.clientRow {
  cursor: pointer;
  padding: 6px 4px;
  border-bottom: 1px solid $black;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.clientRow__infos {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow: hidden;
}

.clientRow__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
