@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.dot-flashing {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $dark-grey;
  color: $dark-grey;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $dark-grey;
  color: $dark-grey;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $dark-grey;
  color: $dark-grey;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: $dark-grey;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}