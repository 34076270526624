@import "../../../globals/styles/_variables.scss";.login {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 20px;
  min-height: 100%;
}

.login__title {
  margin-bottom: 14px;
}

.login__links {
  margin-top: 20px;
}

.signup {
  width: 100%;
}
