@import "../../../globals/styles/_variables.scss";.OrderError {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 15%;
}

.OrderError__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.OrderError__img {
  width: 107px;
  margin-bottom: 25px;
}

.OrderError__buttons {
  margin-top: 20px;
}

.OrderError__buttonDisconnect {
  margin-bottom: 10px;
}

.OrderError__emptyButton {
  height: 40px;
}
