@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.menu {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;

  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.3s;
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

.menu__header__close {
  position: absolute;
  top: 15px;
  right: 0;
  height: 50px;
  padding: 4px 10px;
  z-index: 1001;

  .menu__header__closeIcon {
    font-size: 22px;
    height: 100%;
    display: flex;
    align-items: center;
    
    cursor: pointer;
  }
}

.menu__main {
  background-color: $beige;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  flex-grow: 1;
}

.menu__logo {
  width: 50px;
  margin-bottom: 18px;
}

.menu__link {
  margin-bottom: 10px;
}

.menu__footer {
  background-color: $white;
  padding: 30px 15% 18px;
}
