@import "_variables.scss";@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "./variables.scss";

html, body {
  padding: 0; margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: $black;
}

p {
  margin: 0;
  font-size: 12px;
}

#root {
  height: 100%;
}

.title {
  font-family: Playfair Display, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  color: $black;
  text-align: center;
}

h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: $black;
  text-align: center;
}

h2 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  text-align: center;
}

.ant-tooltip-inner {
  border-radius: 0;
  background-color: $dark-grey;
}
.ant-tooltip-arrow-content {
  background-color: $dark-grey;
}

@for $i from 1 through 8 {
  .mt-#{$i} {
    margin-top: $i * 8px !important;
  }
  .mr-#{$i} {
    margin-right: $i * 8px !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 8px !important;
  }
  .ml-#{$i} {
    margin-left: $i * 8px !important;
  }
  .mx-#{$i} {
    margin-left: $i * 8px !important;
    margin-right: $i * 8px !important;
  }
  .my-#{$i} {
    margin-top: $i * 8px !important;
    margin-bottom: $i * 8px !important;
  }
  .mxy-#{$i} {
    margin-left: $i * 8px !important;
    margin-right: $i * 8px !important;
    margin-top: $i * 8px !important;
    margin-bottom: $i * 8px !important;
  }
}