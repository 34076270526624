@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables.scss";

.cart {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cart__titleLine {
  width: 70%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $black;
}

.cart__title {
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -27px;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
}

.cart__orderButton {
  margin-bottom: 10px;
}

.cart__textButton {
  :global(a) {
    text-decoration: none;
  }
}