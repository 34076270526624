@import "../../../globals/styles/_variables.scss";.deleteCart {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 15%;
}

.deleteCart__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.deleteCart__img {
  width: 107px;
  margin-bottom: 25px;
}

.deleteCart__buttons {
  margin-top: 20px;
}

.deleteCart__buttonYes {
  margin-bottom: 10px;
}
