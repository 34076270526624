@import "../../globals/styles/_variables.scss";.errorPage {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 15%;
}

.errorPage__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.errorPage__img {
  width: 107px;
  margin-bottom: 25px;
}

.errorPage__buttons {
  margin-top: 20px;
}

.errorPage__buttonBack {
  margin-bottom: 10px;
}

.errorPage__emptyButton {
  height: 40px;
}