@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.header {
  position: relative;
  height: 80px;
  padding-top: 15px;
  padding-bottom: 15px;

  background-color: white;
  border-bottom: 2px solid $black;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header_edge {
  position: absolute;
  bottom: 2.5%;
  left: 0;
  right: 0;

  border-bottom: 0.5px solid $black;
}

.header__logo {
  height: 52px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header__hamburger {
  padding: 4px 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.header__hamburgerIcon {
  font-size: 22px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header__infoIcon {
  padding: 4px 10px;
  cursor: pointer;
}

main {
  height: calc(100% - 80px);
}
