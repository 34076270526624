@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables.scss";

.selectClient__filters {
  padding: 25px;
  background-color: $beige;
}

.selectClient__clients {
  padding: 0 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  :global(.ant-spin) {
    padding: 30px 0;
  }
}

