/*
** Colors
*/
$primary: #1EA7FD;
$secondary: #333333;

$white: #FFFFFF;
$black: #000000;

$dark-brown: #231F20;
$dark-grey: #2D2D2D;
$beige: #F9F7F6;