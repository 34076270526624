@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.itemRow {
  width: 100%;
  border-bottom: 0.6px solid $black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 20px;
}

.itemRow__image {
  object-fit: cover;
  width: 65px;
  height: 60px;
}

.itemRow__info {
  height: 60px;
  flex-grow: 1;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.itemRow__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $dark-grey;
}

.itemRow__code {
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.454545px;
  color: $dark-grey;
}

.itemRow__increment {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.itemRow__incrementButton {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: $beige;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemRow__number {
  font-family: Playfair Display, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  margin-top: -6px;
  color: $black;

  margin-left: 18px;
  margin-right: 18px;

  min-width: 40px;
  text-align: center;
}

.itemRow__delete {
  margin-left: auto;
  margin-right: 30px;
  background-color: transparent;
  border: none;
}