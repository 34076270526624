@import "../../../globals/styles/_variables.scss";.orderSuccess {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 15%;
}

.orderSuccess__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.orderSuccess__orderNumber {
  font-weight: 600;
  font-size: 16px;
}

.orderSuccess__img {
  width: 107px;
  margin-bottom: 25px;
}

.orderSuccess__buttons {
  margin-top: 20px;
}

.orderSuccess__buttonDisconnect {
  margin-bottom: 10px;
}
