@import "../../../globals/styles/_variables.scss";.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.product__description {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 22px 15%;
}

.product__image {
  width: 65px;
  margin-bottom: 15px;
}

.product__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.product__code {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.454545px;
  margin-bottom: 5px;
}

.product__price {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.product__discontinued {
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid $black;

  & > * {
    display: flex;
    align-items: center;
  }

  &--text {
    font-size: 20px;
    margin-right: 8px;
  }
}

.bottomMenu__button {
  margin-top: 10px;
  a {
    text-decoration: none;
  }
}