@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.bottomMenuLayout {
  width: 100%;
  height: auto;
  padding: 30px 50px;
  background-color: $beige;
  display: flex;
  flex-direction: column;
}
