@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.splash_screen_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;

    img {
        max-width: 88px;
    }

    h1, h2, p {
        text-transform: uppercase;
        font-weight: 400;
    }

    .splash_screen_title {
        padding-bottom: 8px;
        margin: 8px auto;
        border-bottom: 1px solid $black;

        h1 {
            font-size: 20px;
            letter-spacing: 4px;
        }

        h2 {
            font-size: 24px;
            letter-spacing: 2px;
            margin-bottom: 8px;
        }
    }

    .splash_screen_description {
        & > p:first-child {
            margin-top: 8px;
            font-size: 14px;
            margin-bottom: 8px;
        }

        & > p:last-child {
            margin-top: 4px;
            font-size: 20px;
            letter-spacing: 4px;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;

        & > p {
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;

            & > a {
                color: $black;
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 20%;
        margin-bottom: 32px;

        .body__logo {
            height: 40%;

            & > img {
                height: 100%;
            }
        }

        .body__title {
            & > h1 {
                font-family: Playfair Display, serif;
                font-style: normal;
                margin-bottom: 32px;
                font-size: 28px;
                font-weight: 500;
                line-height: 32px;
            }

            & > h2 {
                font-size: 16px;
                font-weight: 300;
            }
        }

        .body__button {
            width: 100%;

            button {
                text-transform: uppercase;
            }

            &--counter {
                margin-bottom: 32px;
            }
        }
    }
}