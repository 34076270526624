@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.manualInput {
  //position: absolute;
  //bottom: 0;
  width: 100%;
  background-color: $beige;
  height: 50%;
  padding: 30px 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.manualInput__small {
  flex-grow: 1;
  height: auto;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
}

.manualInput {

  :global(.ant-input-group) {
    border-bottom: 1px solid $dark-grey;
    border-radius: 0;
    :global(.ant-input) {
      border: none;
    }
    :global(.ant-btn) {
      background-color: transparent;
      border: none;
    }
    :global(.ant-input-group-addon) {
      border-radius: 0;
      background-color: transparent;
    }
  }

}
