@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.deliveryHome__form {
  flex-grow: 1;
  padding-top: 20px;
}

.deliveryHome__inputs {

}

.deliveryHome__area {
  :global(.ant-input) {
    height: 70px;
    border: 1px solid $dark-grey;
    border-radius: 0;
    box-shadow: none;
  }
  margin-bottom: 10px;
}

.deliveryHome__submit {
  margin-top: auto;
  margin-bottom: 20px;
}
