@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables";

.DeliveryHome__itemRow {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  cursor: pointer;
  padding: 8px;

  :global(.ant-checkbox-inner) {
    border-radius: 0;
    height: 24px;
    width: 24px;
    border-color: $dark-grey;
    margin-top: -1px;
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: $dark-grey;
  }
  :global(.ant-checkbox-inner:after) {
    width: 8px;
    height: 14px;
    top: 45%
  }

  &.DeliveryHome__itemRow--checked {
    background-color: $secondary;
    transition: background 200ms;

    .DeliveryHome__itemRowText > * {
      color: $white;
      font-weight: bold;
    }
  }
}

.DeliveryHome__itemRowCheckbox {
  margin-right: 16px;
  margin-left: 4px;
}

.DeliveryHome__itemRowText {

  &.DeliveryHome__itemRowSelected {
    border: 1px solid $dark-grey;
  }

  h2 {
    text-align: left;
  }
}
