@import "../../globals/styles/_variables.scss";.Infos {
  position: relative;
  width: 100%;
  text-align: center;
}

.Infos__h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.Infos__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.454545px;
  text-align: left;
}

.Infos__backButton {
  position: absolute;
  top: 20px;
  left: 20px;
}
