@import "_variables.scss";.switch-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}