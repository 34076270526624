@import "../../../globals/styles/_variables.scss";@import "../../../globals/styles/variables.scss";

.delivery {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.delivery__titleLine {
  width: 80%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid $black;
}

.delivery__title {
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -26px;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
}

.delivery__container {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.delivery__menu {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: .5px solid #2d2d2d;
  min-height: 46px;
}

.delivery__menuItem {
  flex: 1;
  display: grid;
  place-items: center;
  font-weight: 300;
  font-size: 10px;
  cursor: pointer;
  height: 100%;
  background-color: transparent;
  border: none;
}

.delivery__menuItem.delivery__menuItem--favoriteSelected {
  font-weight: 500;
}

.delivery__menuItem.delivery__menuItem--customSelected {
  font-weight: 500;
}

.delivery__menuItem--favoriteSelected::after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #2d2d2d;
}

.delivery__menuItem--customSelected::after {
  content: "";
  position: absolute;
  width: 50%;
  bottom: 0;
  right: 0;
  border-bottom: 2px solid #2d2d2d;
}

.delivery__section {
  & > div {
    display: flex;
  }

  &--checkbox {
    margin-right: 8px;
    
    :global(.ant-checkbox-inner) {
      border-radius: 0;
      height: 16px;
      width: 16px;
      border-color: $dark-grey;
      margin-top: -1px;
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: $dark-grey;
    }

    :global(.ant-checkbox-inner:after) {
      width: 6px;
      height: 10px;
      top: 45%
    }
  }

  &--selection {
    margin-top: 2px;
    width: 100%;
  }
}

.delivery_or {
  text-align: left;
  margin-top: 6px;
  margin-bottom: 12px;
  margin-left: 24px;
  font-size: 16px;
}

.delivery_info {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
}

.delivery__submit {
  margin-top: 10px;
  p {
    text-transform: uppercase;
  }
}
