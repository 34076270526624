@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.container {
  position: relative;
}

.input input {
  padding-right: 16px !important;
}

.icon {
  position: absolute;
  top: 12px;
  right: 0;
}