@import "../../globals/styles/_variables.scss";@import "../../globals/styles/variables.scss";

.itemList {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border: 1px solid $dark-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $dark-grey;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
}

.itemList__emptyText {
  display: flex;
  align-items: center;
  flex-grow: 1;
  align-self: center;
}
